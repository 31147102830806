import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sånn går du frem`}</h2>
    <p>{`Når du vil melde inn en feil på en komponent eller annet innhold i designsystemet har du flere muligheter:`}</p>
    <ul>
      <li parentName="ul">{`Opprett et `}<a parentName="li" {...{
          "href": "https://github.com/SpareBank1/designsystem/issues/new/choose"
        }}>{`issue på GitHub`}</a></li>
      <li parentName="ul">{`Send en e-post til `}<a parentName="li" {...{
          "href": "mailto:designsystem@sparebank1.no"
        }}>{`designsystem@sparebank1.no`}</a></li>
      <li parentName="ul">{`Post i `}<strong parentName="li">{`#ext-designsystem`}</strong>{` på Slack`}</li>
    </ul>
    <h2>{`Kom gjerne med løsningsforslag`}</h2>
    <p>{`Den raskeste måten å få feilen rettet på er å komme med et konkret forslag til hvordan den kan løses, som designsystem-teamet kan kvalitetssikre før det publiseres ut i designsystemet.`}</p>
    <p>{`Det er mange ulike ønsker blant alle som bruker designsystemet. For å kunne møte flest mulig av behovene er vi avhengige av at teamene bidrar aktivt inn med forslag til løsninger.`}</p>
    <p>{`Les mer om `}<a parentName="p" {...{
        "href": "/bidra/kom-med-losningsforslag/"
      }}>{`hvordan du kommer med forslag til løsninger`}</a>{` i designsystemet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      